.position-absolute-top-minus-10 {
  top: -10px;
}

.position-absolute-right-minus-5 {
  right: -5px;
}

.position-absolute-top-60 {
  top: 60px;
}
