@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&family=Rozha+One&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-primary: #bf783c;
  --color-secondary: #d5b18f;
  --color-tertiary: #fbe9e5;
  --color-quaternary: #5a5a5a;
  --color-quinary: #e9e9e9;
  --color-borderPrimary: #c6c6c6;
  --color-borderSecondary: #b4b4b4;
}

input:focus {
  outline: none;
}

input[type="file"] {
  display: none;
}

textarea {
  resize: none;
}

textarea:focus {
  outline: none;
}
