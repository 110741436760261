.scrollbar-card::-webkit-scrollbar {
  width: 4px;
}

.scrollbar-card::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbar-card::-webkit-scrollbar-thumb {
  background-color: var(--color-borderPrimary);
  border-radius: 4px;
}

.scrollbar-card::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-borderSecondary);
}

.scrollbar-card::-webkit-scrollbar-thumb:vertical {
  margin: 10px 10px;
  height: 85%;
}

.scrollbar-modal::-webkit-scrollbar {
  width: 4px;
}
