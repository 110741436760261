.font-primary {
  font-family: "Rozha One";
}

.font-secondary {
  font-family: "Poppins";
}

.font-size-8 {
  font-size: 8px;
}
