.button-primary {
  height: 60px;
  width: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  color: var(--color-white);
  text-transform: uppercase;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .button-primary {
    height: 40px;
    width: 120px;
    font-size: 12px;
  }
}
