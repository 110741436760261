.max-width-60percent {
  max-width: 60%;
}

.max-width-450px {
  max-width: 450px;
}

.max-width-400px {
  max-width: 400px;
}
