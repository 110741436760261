.gradient-hero-background {
  background: linear-gradient(
    to bottom,
    var(--color-tertiary),
    var(--color-white)
  );
}

.gradient-footer-background {
  background: linear-gradient(
    to top,
    var(--color-tertiary),
    var(--color-white)
  );
}
