.progress-bar {
  height: 4px;
  width: 96%;
  position: fixed;
  top: 0;
  left: 2%;
  right: 2%;
  background-color: var(--color-primary);
  z-index: 9999;
  opacity: 0;
  transform: scaleX(0);
  transition: transform 2000ms ease-in-out, opacity 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filler {
  height: 100%;
  width: 100%;
  background-color: var(--color-primary);
  transform-origin: left;
}

.loading {
  opacity: 1;
  transform: scaleX(1);
}
