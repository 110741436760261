/* Width classes */
.percent-width-10 {
  width: 10%;
}

.percent-width-20 {
  width: 20%;
}

.percent-width-30 {
  width: 30%;
}

.percent-width-40 {
  width: 40%;
}

.percent-width-50 {
  width: 50%;
}

.percent-width-60 {
  width: 60%;
}

.percent-width-70 {
  width: 70%;
}

.percent-width-80 {
  width: 80%;
}

.percent-width-90 {
  width: 90%;
}

/* Height classes */
.percent-height-10 {
  height: 10%;
}

.percent-height-20 {
  height: 20%;
}

.percent-height-30 {
  height: 30%;
}

.percent-height-40 {
  height: 40%;
}

.percent-height-50 {
  height: 50%;
}

.percent-height-60 {
  height: 60%;
}

.percent-height-70 {
  height: 70%;
}

.percent-height-80 {
  height: 80%;
}

.percent-height-90 {
  height: 90%;
}
