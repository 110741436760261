.width-45percent {
  width: 45%;
}

.width-86percent {
  width: 86%;
}

.width-650px {
  width: 650px;
}
